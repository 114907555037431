"use client";
import React, { useRef, useEffect, PropsWithChildren } from "react";
import { gsap } from "gsap";
import { cn } from "@/utils/cn";

export const MouseProximity: React.FC<PropsWithChildren<{ className: string }>> = ({ children, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;
    if (!container || !content) return;

    const handleMouseEnter = (e: MouseEvent) => {
      if (!content) return;
      const { left, top, width, height } = content.getBoundingClientRect();
      const relX = e.clientX - left;
      const relY = e.clientY - top;

      parallaxIt(content, relX, relY, width, height, 20);
      // gsap.to(container, { duration: 0.3, css: { height: 200, width: 200 } });
      // gsap.to(content, { duration: 0.3, scale: 1.3 });
    };

    const handleMouseLeave = () => {
      // gsap.to(container, { duration: 0.3, css: { height: 150, width: 150 } });
      gsap.to(content, { duration: 0.9, x: 0, y: 0, ease: "easeOut" });
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!content) return;
      const { left, top, width, height } = content.getBoundingClientRect();
      const relX = e.clientX - left;
      const relY = e.clientY - top;

      parallaxIt(content, relX, relY, width, height, 20);
      // parallaxIt(content, relX, relY, width, height, 40);
    };

    const parallaxIt = (
      target: gsap.TweenTarget,
      relX: number,
      relY: number,
      containerWidth: number,
      containerHeight: number,
      movement: number
    ) => {
      gsap.to(target, {
        duration: 0.9,
        x: ((relX - containerWidth / 2) / containerWidth) * movement,
        y: ((relY - containerHeight / 2) / containerHeight) * movement,
        ease: "easeOut",
      });
    };

    container.addEventListener("mouseenter", handleMouseEnter);
    container.addEventListener("mouseleave", handleMouseLeave);
    container.addEventListener("mousemove", handleMouseMove);

    return () => {
      container.removeEventListener("mouseenter", handleMouseEnter);
      container.removeEventListener("mouseleave", handleMouseLeave);
      container.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div ref={containerRef} className={cn("flex justify-center items-center relative", className)}>
      <div ref={contentRef} className="">
        {children}
      </div>
    </div>
  );
};
