"use client";
import { cn } from "@/utils/cn";
import { useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";

declare global {
  interface Window {
    clarity: any;
    dataLayer?: any[];
    gtag?: (...args: any[]) => void;
  }
}

type Clarity = {
  init: (id: string) => void;
};

const ConsentBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [shouldRender, setShouldRender] = useState(true);
  const [showMore, setShowmore] = useState(false);
  const t = useTranslations("Cookie");

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("legal-banner") === "true") {
        enableAnalytics();
      }
      if (localStorage.getItem("legal-banner") === null) {
        setIsVisible(true);
        setShouldRender(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 300);
      return () => clearTimeout(timer);
    }
    setShouldRender(true);
  }, [isVisible]);

  const enableAnalytics = async () => {
    try {
      const { default: ReactGA } = await import("react-ga4");
      const { clarity } = await import("react-microsoft-clarity");
      const { install } = await import("ga-gtag");

      try {
        clarity.init("dq0ezsrcmd");
      } catch (clarityError) {
        console.log(clarityError);
      }

      ReactGA.initialize("G-1FTP9BL0KM");
      install("GTM-KGPXTNM2");
      console.log("Analytics enabled - Production mode");

      ReactPixel.init("627333142982345");
      ReactPixel.pageView();
    } catch (error) {
      console.error("Error initializing analytics:", error);
    }
  };

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.setItem("legal-banner", "true");
    console.log("Analytics e Clarity attivati");
    enableAnalytics();
  };

  const handleDecline = () => {
    setIsVisible(false);
    localStorage.setItem("legal-banner", "false");
  };

  if (!shouldRender) return null;

  return (
    <div
      className={cn(
        "fixed bottom-4 right-4 left-4 md:left-auto z-50 md:max-w-lg",
        "bg-white rounded-lg shadow-lg p-4",
        "transition-all duration-300 ease-in-out transform",
        isVisible ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
      )}
    >
      <h2 className="text-base font-medium mb-3">{t("respectYourPrivacy")}</h2>
      <p className="text-sm text-gray-600 mb-4">{t("useCookiesToImprove")}</p>
      <div className="flex flex-row items-center justify-between gap-4">
        <div className="flex gap-4 md:gap-6 text-sm">
          <a href="/cookies">Cookie</a>
          <a href="/privacy">{t("terms")}</a>
        </div>
        <div className="flex gap-4 md:gap-6 items-center">
          {!showMore && (
            <a href="#" className="text-sm" onClick={() => setShowmore(true)}>
              {t("showMore")}
            </a>
          )}
          {showMore && (
            <a href="#" className="text-sm" onClick={handleDecline}>
              {t("reject")}
            </a>
          )}
          <button
            onClick={handleAccept}
            className="px-6 py-2 bg-violet-600 text-white text-sm rounded-full hover:bg-violet-700 transition duration-300"
          >
            {t("accept")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsentBanner;
