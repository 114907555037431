export const throttle = <T extends any[]>(func: (...args: T) => void, limit: number) => {
  let inThrottle: boolean;
  let lastArgs: T | undefined;
  return function (this: any, ...args: T) {
    if (!inThrottle) {
      if (args.length > 0) {
        func.apply(this, args);
      }
      inThrottle = true;
      if (lastArgs) {
        func.apply(this, lastArgs);
        lastArgs = undefined;
      }
      setTimeout(() => {
        inThrottle = false;
      }, limit);
    } else {
      lastArgs = args;
    }
  };
};
