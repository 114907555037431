"use client";
import React from "react";
import { useLocale } from "next-intl";
import { usePathname } from "@/i18n/routing";
import { cn } from "@/utils/cn";

interface LanguageSwitcherProps {
  theme?: "light" | "dark";
  lenis?: any;
  className?: string;
}

export default function LanguageSwitcher({ theme, lenis, className }: LanguageSwitcherProps) {
  const locale = useLocale();
  const pathname = usePathname();

  const targetLocale = locale === "it" ? "en" : "it";

  const targetPathname = `/${targetLocale}${
    pathname.startsWith(`/${locale}`) ? pathname.slice(locale.length + 1) : pathname
  }`;

  const handleLanguageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (lenis) lenis.stop();
    setTimeout(() => {
      window.location.href = targetPathname;
    }, 50);
  };

  return (
    <button
      onClick={handleLanguageChange}
      className={cn(
        "language-switcher-button border rounded-full py-0.5 px-3.5 text-center",
        {
          "border-dark-gray": theme === "light",
          "border-white": theme === "dark",
          "text-white bg-dark-gray": theme === "light" && targetLocale === "it",
          "text-dark-gray bg-white": theme === "dark" && targetLocale === "it",
        },
        className
      )}
    >
      {targetLocale === "it" ? "Italiano" : "English"}
    </button>
  );
}
