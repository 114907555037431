import { create } from 'zustand';
import { combine } from 'zustand/middleware';

export const useAnimate = create(
  combine(
    {
      isAnimating: true,
    },
    (set) => ({
      setIsAnimating: (isAnimating: boolean) => set({ isAnimating }),
    })
  )
);
