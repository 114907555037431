"use client";
import React, { useEffect } from "react";

const favIconUrl = (mode: string, index: number) => {
  return `/favicon-${mode}/${mode}_${index + 1}.png`;
};

const imageCount = 60;

export const FaviconAnimator = () => {
  return null;
  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let imageCounter = 0;

    document.querySelectorAll("link[rel='icon'], link[rel='shortcut icon']").forEach((el) => el.remove());

    let linkElement = document.createElement("link");
    linkElement.rel = "icon";
    document.head.appendChild(linkElement);

    const updateFavicon = () => {
      const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      linkElement.href = favIconUrl(isDarkMode ? "light" : "dark", imageCounter);
      imageCounter = (imageCounter + 1) % imageCount;
    };

    if (isSafari) {
      return;
    }

    updateFavicon();

    const intervalId = setInterval(updateFavicon, 400);
    return () => {
      clearInterval(intervalId);
      linkElement.href = favIconUrl(window.matchMedia("(prefers-color-scheme: dark)").matches ? "light" : "dark", 0);
    };
  }, []);

  return null;
};

export default FaviconAnimator;
