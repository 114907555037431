import { create } from 'zustand';
import { combine } from 'zustand/middleware';

export const useBlur = create(
  combine(
    {
      isBlur: false,
    },
    (set) => ({
      setIsBlur: (isBlur: boolean) => set({ isBlur }),
    })
  )
);
