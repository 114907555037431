import { gql } from "graphql-request";

export const GET_BLOG_DATA = gql`
  query GetBlogData($first: Int!, $excludeId: ID, $after: String, $categoryId: Int) {
    latestPost: posts(first: 1, where: { orderby: { field: DATE, order: DESC } }) {
      edges {
        node {
          id
          title
          excerpt
          date
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }

    posts(
      first: $first
      after: $after
      where: { categoryId: $categoryId, notIn: [$excludeId], orderby: { field: DATE, order: DESC } }
    ) {
      edges {
        node {
          id
          title
          excerpt
          date
          slug
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }

    categories(where: { parent: 0 }) {
      nodes {
        name
        databaseId
      }
    }
  }
`;

export const GET_POST_AND_LATEST = gql`
  query GetPostAndLatest($id: ID!) {
    post(id: $id, idType: URI) {
      id
      title
      excerpt
      content
      date
      slug
      uri
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
    posts(first: 100, where: { orderby: { field: DATE, order: DESC } }) {
      edges {
        node {
          id
          databaseId
          title
          excerpt
          date
          slug
          uri
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SendEmail($input: SendEmailInput!) {
    sendEmail(input: $input) {
      sent
      to
      replyTo
      origin
      message
      clientMutationId
    }
  }
`;
