"use client";
import { ReactLenis } from "@studio-freight/react-lenis";

export default function ScrollSmoother({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ReactLenis root options={{ }}>
      {children}
    </ReactLenis>
  );
}
