"use client";

import {
  AppDevelop,
  Branding,
  DigitalStrategy,
  Lettering,
  LogoMark,
  PlusIcon,
  Seo,
  SoftwareDevelop,
  UxUi,
  Website,
} from "./icons";
import { ReactNode, useEffect, useRef, useState } from "react";
import { cn } from "@/utils/cn";
import { Button } from "./button";
import { usePathname } from "next/navigation";
import { MouseProximity } from "./animations/mouse-proximity";
import { useAnimate } from "@/store/animate";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { throttle } from "@/utils/throttle";
import { useLenis } from "@studio-freight/react-lenis";
import { useBlur } from "@/store/blur";
import { useTranslations } from "next-intl";
import { routing } from "@/i18n/routing";
import Link from "next/link";
import LanguageSwitcher from "./language-switcher";
import { createPortal } from "react-dom";
import Lottie from "lottie-web";
import appLottie from "@/assets/sviluppo-app.json";
import websiteLottie from "@/assets/siti-web.json";
import softwareLottie from "@/assets/sviluppo-software.json";
import digitalStrategyLottie from "@/assets/strategia-digitale.json";
import seoLottie from "@/assets/seo.json";
import brandingLottie from "@/assets/branding.json";
import uxUiLottie from "@/assets/ux-ui.json";
import { useContactForm } from "@/store/contact-form";
gsap.registerPlugin(ScrollTrigger);

interface NavItemProps {
  label: string;
  href: { it: string; en: string };
  external?: boolean;
  subItems?: {
    label: string;
    href: { it: string; en: string };
    icon?: ReactNode;
    lottie?: any;
  }[];
}

const NavItem: React.FC<NavItemProps> = ({ label, href, external, subItems }) => {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState(false);
  const currentLocale = routing.locales.find((locale) => pathname.startsWith(`/${locale}`)) || routing.defaultLocale;
  const localizedHref = `/${currentLocale}${href[currentLocale]}`.replace(/\/{2,}/g, "/");
  const normalizePath = (path: string) => path.replace(/\/+$/, "");
  const isActive =
    normalizePath(pathname) === normalizePath(localizedHref) || pathname.startsWith(normalizePath(localizedHref));
  const buttonRef = useRef<HTMLDivElement>(null);

  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [positionCalculated, setPositionCalculated] = useState(false);
  const lastHoveredItemRef = useRef<string | null>(null);

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom,
        left: rect.left - (8.5 * window.innerWidth) / 100,
      });
      setPositionCalculated(true);
    } else {
      setPositionCalculated(false);
      // Only clear hoveredItem when menu is fully closed
      setHoveredItem(null);
    }
  }, [isOpen]);

  const activeSubItem = subItems?.find(
    (subItem) => normalizePath(pathname) === normalizePath(`/${currentLocale}${subItem.href[currentLocale]}`)
  );

  useEffect(() => {
    if (isOpen && activeSubItem) {
      setHoveredItem(activeSubItem.label);
      lastHoveredItemRef.current = activeSubItem.label;
    }
  }, [isOpen, activeSubItem]);

  // Helper functions to make conditional logic more readable
  const isServicesPath = (path: string) => {
    return path.includes("/servizi") || path.includes("/services");
  };

  const isServiceDetailPage = (path: string) => {
    return isServicesPath(path) && path.split("/").length > 3;
  };

  // Determine if animation/detail content should be visible
  const isDetailContentVisible = () => {
    return hoveredItem || lastHoveredItemRef.current || isServiceDetailPage(pathname);
  };

  return (
    <div
      className="relative group"
      ref={buttonRef}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => {
        setIsOpen(false);
        setHoveredItem(null);
        lastHoveredItemRef.current = null;
      }}
    >
      <Link
        href={localizedHref}
        className={cn("flex items-center group relative hover:drop-shadow-sm shadow-white p-4", {
          "opacity-100": isActive || isOpen,
          "opacity-50 group-hover:opacity-100": !isActive && !isOpen,
        })}
        target={external ? "_blank" : ""}
        aria-label="Navigate to Blog"
      >
        {!external && subItems && (
          <>
            <div className="absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 flex-1 transition-all duration-300">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 6L8 10L4 6"
                  stroke={isActive || isOpen ? "#83C512" : "#5B5D6B"}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="transition-colors duration-300"
                />
              </svg>
            </div>
          </>
        )}
        {!external && !subItems && (
          <>
            <div
              className={cn(
                "absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-100 scale-0 flex-1 size-[.5rem] transition-all border border-solid border-[#83C512] duration-300 rounded-full overflow-hidden",
                {
                  "scale-100": isActive,
                }
              )}
            ></div>
            <div
              className={cn(
                "absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 size-[.5rem] bg-[#83C512] scale-0 rounded-full transition-all duration-200 ease-in",
                {
                  "scale-100": isActive,
                }
              )}
            ></div>
          </>
        )}
        {external && (
          <div className="absolute -left-0.5 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-all opacity-0 duration-300 ease-in group-hover:opacity-100">
            <svg
              stroke="currentColor"
              fill="currentColor"
              className="fill-[#83C512] size-5"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M6 6v2h8.59L5 17.59 6.41 19 16 9.41V18h2V6z"></path>
            </svg>
          </div>
        )}
        {label}
      </Link>
      {subItems &&
        isOpen &&
        positionCalculated &&
        typeof window !== "undefined" &&
        createPortal(
          <div
            style={{
              position: "fixed",
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              maxWidth: `calc(100vw - ${menuPosition.left}px - 3rem)`,
              width: "auto",
              boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
              transition: "width 0.3s ease-in-out",
            }}
            className={cn("flex bg-white text-lg text-dark-gray font-normal rounded-2xl p-5 z-50")}
          >
            <div className="w-72 flex-shrink-0 space-y-2.5 pr-4">
              <div className="flex gap-4 items-center text-lg group/item mb-2">
                <span
                  className={cn("icon", {
                    "[&>svg>g]:!opacity-100 [&>svg>g>path]:!stroke-purple [&>svg>g]:!stroke-purple [&>svg>path]:!stroke-purple [&>svg>path]:!opacity-100":
                      isServicesPath(pathname) && !isServiceDetailPage(pathname),
                    "group-hover/item:[&>svg>g]:!opacity-100 group-hover/item:[&>svg>g>path]:!stroke-purple group-hover/item:[&>svg>g]:!stroke-purple group-hover/item:[&>svg>path]:!stroke-purple group-hover/item:[&>svg>path]:!opacity-100":
                      true,
                  })}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.2">
                      <path
                        d="M12 5V19M5 12H19"
                        stroke="#1C212E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </span>
                <Link
                  href={localizedHref}
                  className={cn("group-hover/item:text-purple", {
                    "text-purple": isServicesPath(pathname) && !isServiceDetailPage(pathname),
                  })}
                >
                  <div>{currentLocale === "it" ? "I nostri servizi" : "Our services"}</div>
                </Link>
              </div>
              <div className="w-full h-px bg-gray-200 my-3 mr-8"></div>
              {subItems.map((subItem) => {
                const localizedSubHref = subItem.href[currentLocale];
                const isSubItemActive =
                  normalizePath(pathname) === normalizePath(`/${currentLocale}${subItem.href[currentLocale]}`);

                return (
                  <div
                    key={subItem.label}
                    className="flex gap-4 items-center text-lg group/item"
                    onMouseEnter={() => {
                      setHoveredItem(subItem.label);
                      lastHoveredItemRef.current = subItem.label;
                    }}
                    onMouseLeave={() => {
                      lastHoveredItemRef.current = subItem.label;
                    }}
                  >
                    <span
                      className={cn("icon", {
                        "group-hover/item:[&>svg>g]:!opacity-100 group-hover/item:[&>svg>g>path]:!stroke-purple group-hover/item:[&>svg>g]:!stroke-purple group-hover/item:[&>svg>path]:!stroke-purple group-hover/item:[&>svg>path]:!opacity-100":
                          true,
                        "[&>svg>g]:!opacity-100 [&>svg>g>path]:!stroke-purple [&>svg>g]:!stroke-purple [&>svg>path]:!stroke-purple [&>svg>path]:!opacity-100":
                          isSubItemActive,
                        "ux-ui-icon group-hover/item:[&>svg>path]:!fill-purple": subItem.label === "UX/UI",
                      })}
                    >
                      {subItem.icon}
                    </span>
                    <Link
                      href={localizedSubHref}
                      className={cn("group-hover/item:text-purple", {
                        "text-purple": isSubItemActive,
                      })}
                    >
                      <div>{subItem.label}</div>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div
              className={cn(
                "rounded-xl bg-light-gray overflow-hidden transition-all duration-300 delay-150 ease-in-out",
                {
                  "w-128 opacity-100": isDetailContentVisible(),
                  "w-0 opacity-0": !isDetailContentVisible(),
                }
              )}
            >
              <div
                className={cn("relative w-full h-full", {
                  "bg-white": !isDetailContentVisible(),
                })}
              >
                {subItems.map((subItem) => {
                  const isSubItemActive =
                    normalizePath(pathname) === normalizePath(`/${currentLocale}${subItem.href[currentLocale]}`);

                  const shouldShowLottie =
                    hoveredItem === subItem.label ||
                    lastHoveredItemRef.current === subItem.label ||
                    (isServicesPath(pathname) && !hoveredItem && isServiceDetailPage(pathname) && isSubItemActive);

                  return (
                    shouldShowLottie &&
                    subItem.lottie && (
                      <div
                        key={subItem.label}
                        ref={(element) => {
                          if (element && shouldShowLottie) {
                            const anim = Lottie.loadAnimation({
                              container: element,
                              animationData: subItem.lottie,
                              loop: true,
                              autoplay: true,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid meet",
                                progressiveLoad: true,
                                hideOnTransparent: true,
                              },
                            });

                            // Cleanup animation when component unmounts or when hover changes
                            return () => {
                              // Only destroy the animation when the menu is actually closing
                              // not when just moving between items
                              if (!isOpen) {
                                anim.destroy();
                                if (element) {
                                  element.innerHTML = "";
                                }
                              } else {
                                // For transitions between items, use a longer delay
                                setTimeout(() => {
                                  // Only destroy if we're not showing this animation anymore
                                  if (
                                    element &&
                                    !shouldShowLottie &&
                                    hoveredItem !== subItem.label &&
                                    lastHoveredItemRef.current !== subItem.label
                                  ) {
                                    anim.destroy();
                                    element.innerHTML = "";
                                  }
                                }, 300);
                              }
                            };
                          }
                        }}
                        className="absolute -bottom-2 left-1/2 -translate-x-1/2 w-[105%] h-[105%] transition-transform delay-150 duration-300"
                      />
                    )
                  );
                })}
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export const Header = () => {
  const t = useTranslations("Header");
  const pathname = usePathname();
  const lenis = useLenis();
  const lastYPosRef = useRef(0);
  const [hidden, setHidden] = useState(false);
  const { isAnimating, setIsAnimating } = useAnimate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { isBlur } = useBlur();
  const currentLocale = routing.locales.find((locale) => pathname.startsWith(`/${locale}`)) || routing.defaultLocale;
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { open: openContactForm } = useContactForm();

  const isServicesPath = (path: string) => {
    return path.includes("/servizi") || path.includes("/services");
  };

  const isServiceDetailPage = (path: string) => {
    return isServicesPath(path) && path.split("/").length > 3;
  };

  // Check if we're on a subservice landing page
  const isOnServiceDetailPage = isServiceDetailPage(pathname);

  const toggleHidden = throttle((isScrollingUp) => {
    if (isScrollingUp) {
      setHidden(false);
    } else {
      setHidden(true);
    }
  }, 1000);

  const handleScroll = (e?: Event, force?: boolean) => {
    const yPos = window.scrollY;
    const isScrollingUp = force || yPos < lastYPosRef.current;
    toggleHidden(isScrollingUp);
    lastYPosRef.current = yPos;

    const logoMark = document.querySelector<HTMLDivElement>(".scroll-rotation");
    if (logoMark) {
      const rotation = yPos / 5;
      logoMark.style.transform = `rotate(${rotation}deg)`;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [toggleHidden]);

  useEffect(() => {
    if (!isBlur) {
      handleScroll(undefined, true);
    }
  }, [isBlur]);

  useEffect(() => {
    if (pathname !== "/") {
      setIsAnimating(false);
      document.body.style.backgroundColor = "black";
    }
    document.body.style.backgroundColor = "white";

    setTimeout(
      () => {
        setHidden(false);
      },
      pathname === "/" ? 3000 : 200
    );
  }, [pathname, setIsAnimating]);

  useEffect(() => {
    lenis?.stop();
    window.scrollTo(0, 0);
    if (!isAnimating) lenis?.start();
  }, [lenis, isAnimating, pathname]);

  const closeDrawer = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    gsap.utils.toArray(".nav-link").forEach((element, elementIndex) => {
      if (isMenuOpen) {
        gsap.fromTo(
          element as Element,
          { clipPath: "inset(0% 0% 100% 0%)", y: "100%" },
          {
            clipPath: "inset(0% 0% 0% 0%)",
            y: "0%",
            ease: "power3.inOut",
            duration: 0.8,
            delay: elementIndex * 0.1,
          }
        );
      } else {
        gsap.to(element as Element, {
          clipPath: "inset(0% 0% 100% 0%)",
          y: "100%",
          duration: 0.3,
        });
      }
    });

    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const navItems = [
    { label: t("portfolio"), href: { it: "/portfolio", en: "/portfolio" } },
    {
      label: t("services"),
      href: { it: "/servizi", en: "/services" },
      subItems: [
        {
          label: t("appDev"),
          href: { it: "/servizi/sviluppo-app", en: "/services/app-development" },
          icon: <AppDevelop />,
          lottie: appLottie,
        },
        {
          label: t("website"),
          href: { it: "/servizi/realizzazione-siti-web", en: "/services/website-creation" },
          icon: <Website />,
          lottie: websiteLottie,
        },

        {
          label: t("softwareDevelopment"),
          href: { it: "/servizi/sviluppo-software", en: "/services/software-development" },
          icon: <SoftwareDevelop />,
          lottie: softwareLottie,
        },
        {
          label: "UX/UI",
          href: { it: "/servizi/ux-ui-design", en: "/services/ux-ui-design" },
          icon: <UxUi />,
          lottie: uxUiLottie,
        },
        {
          label: t("digitalConsulting"),
          href: { it: "/servizi/consulenza-digitale", en: "/services/digital-consulting" },
          icon: <DigitalStrategy />,
          lottie: digitalStrategyLottie,
        },

        {
          label: "Branding",
          href: { it: "/servizi/branding", en: "/services/branding" },
          icon: <Branding />,
          lottie: brandingLottie,
        },

        {
          label: "SEO",
          href: { it: "/servizi/consulenza-seo-salerno", en: "/services/seo-consulting-salerno" },
          icon: <Seo />,
          lottie: seoLottie,
        },
      ],
    },
    { label: t("aboutUs"), href: { it: "/chi-siamo", en: "/about-us" } },
    { label: t("career"), href: { it: "/carriera", en: "/career" } },
    { label: t("blog"), href: { it: "/blog", en: "/blog" } },
  ];
  return (
    <>
      {!routing.locales.some((locale) => pathname === `/${locale}`) && <div className="h-32"></div>}
      <div>
        <header className="flex mix-blend-difference px-6 md:px-11 h-32 fixed z-40 top-0 left-0 w-full">
          <div
            className={cn(
              "flex justify-between items-center w-full text-lg text-white transition-all in-expo duration-500 translate-y-0",
              {
                "opacity-0 pointer-events-none": hidden || isAnimating,
              }
            )}
          >
            <Link href={`/${currentLocale}`} className="shrink-0" aria-label="Navigate to home">
              <Lettering color="white" className="shrink-0 max-w-40 h-8 mb-[4px]" />
            </Link>

            <nav className="lg:gap-3 xl:gap-12 items-center max-md:flex-wrap max-md:max-w-full hidden lg:flex">
              {navItems.map((item) => (
                <NavItem key={item.label} {...item} />
              ))}
              <LanguageSwitcher className="py-3 px-4 text-sm" />

              {isOnServiceDetailPage ? (
                <Link
                  href="#form-section"
                  onClick={(e) => {
                    e.preventDefault();
                    // Find the form section in service detail pages
                    const formElement = document.getElementById("form-section");
                    if (formElement) {
                      formElement.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  <Button className="opacity-0 px-5 py-2 text-lg">{t("letsTalk")}</Button>
                </Link>
              ) : (
                <Button
                  className="opacity-0 px-5 py-2 text-lg"
                  onClick={() => {
                    openContactForm();
                  }}
                >
                  {t("letsTalk")}
                </Button>
              )}
            </nav>
          </div>
          <button
            className={cn("justify-center block lg:hidden", {
              "opacity-0 pointer-events-none": isAnimating,
            })}
            aria-label="Navigation menu"
          >
            <div
              className="relative w-10 h-10 flex justify-center items-center cursor-pointer"
              onClick={() => {
                setMenuOpen(!isMenuOpen);
                setExpandedMenu(null);
              }}
            >
              <div
                className={`w-8 h-0.5 bg-white absolute transition-all duration-300 ease-in-out 
                  ${isMenuOpen ? "rotate-45" : "-translate-y-1.5"}`}
              ></div>
              <div
                className={`w-8 h-0.5 bg-white absolute transition-all duration-300 ease-in-out 
                  ${isMenuOpen ? "-rotate-45" : "translate-y-1.5"}`}
              ></div>
            </div>
          </button>
        </header>
      </div>

      <div
        id="mobile-menu"
        className={cn(
          "mix-blend-difference px-6 md:px-11 h-32",
          "fixed z-40 top-0 left-0 transition-all ease-out duration-500 translate-y-0",
          "flex justify-between items-center",
          {
            "opacity-0 pointer-events-none": isAnimating,
            "-translate-y-full": !hidden || isAnimating,
          }
        )}
      >
        <Link href="/" className="shrink-0" aria-label="Navigate to home">
          <LogoMark color="white" className="scroll-rotation shrink-0 max-w-44 h-12 mb-[4px]" />
        </Link>
      </div>
      <div
        className={cn("fixed z-50 top-0 right-0 transition-all ease-out duration-300 translate-y-0", {
          "opacity-0": isAnimating,
        })}
      >
        <MouseProximity className="px-6 md:px-11 h-32 hidden lg:flex">
          {isOnServiceDetailPage ? (
            <Link
              href="#form-section"
              onClick={(e) => {
                e.preventDefault();
                // Find the form section in service detail pages
                const formElement = document.getElementById("form-section");
                if (formElement) {
                  formElement.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              <Button color="purple" className="px-5 py-2 text-xl">
                {t("letsTalk")}
              </Button>
            </Link>
          ) : (
            <Button
              color="purple"
              className="px-5 py-2 text-xl"
              onClick={() => {
                openContactForm();
              }}
            >
              {t("letsTalk")}
            </Button>
          )}
        </MouseProximity>
      </div>

      <div
        className={cn(
          "fixed top-0 right-0 left-0 bottom-0 z-50 py-11 px-6 md:px-11 min-h-[100vh] min-safe-h-screen bg-dark-gray transition-opacity duration-300 ease-in-out",
          {
            "opacity-0 pointer-events-none": isAnimating || !isMenuOpen,
            "opacity-100": isMenuOpen,
          }
        )}
      >
        <div className="flex h-full">
          <div className="flex-1 flex flex-col h-full text-white">
            <div>
              {navItems.map((item) => {
                const currentLocale =
                  routing.locales.find((locale) => pathname.startsWith(`/${locale}`)) || routing.defaultLocale;

                const localizedHref = item.href[currentLocale];
                const isExpanded = expandedMenu === item.label;

                return (
                  <div key={item.label} className="flex flex-col">
                    <div className="flex items-center gap-5">
                      {item.subItems ? (
                        <button onClick={() => setExpandedMenu(isExpanded ? null : item.label)} className="text-left">
                          <h2 className="nav-link text-[6vh] hover:text-light-yellow leading-tight">{item.label}</h2>
                        </button>
                      ) : (
                        <Link href={localizedHref} onClick={closeDrawer}>
                          <h2 className="nav-link text-[6vh] hover:text-light-yellow leading-tight">{item.label}</h2>
                        </Link>
                      )}
                      {item.subItems && (
                        <button
                          className="relative z-50 flex -mr-9 text-[6vh] text-light-yellow font-light cursor-pointer"
                          onClick={() => setExpandedMenu(isExpanded ? null : item.label)}
                          aria-label="Toggle sub-menu"
                          aria-expanded={isExpanded}
                          type="button"
                        >
                          <div
                            className={cn("transition-all", {
                              "opacity-0": !isMenuOpen,
                              "opacity-100 delay-700": isMenuOpen,
                            })}
                          >
                            {isExpanded ? (
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M22.5 18.75L15 11.25L7.5 18.75"
                                  stroke="#FFF96B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.5 11.25L15 18.75L22.5 11.25"
                                  stroke="#FFF96B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        </button>
                      )}
                    </div>

                    {item.subItems && (
                      <div
                        className={cn(
                          "overflow-hidden transition-all duration-500 ease-out origin-top",
                          isExpanded ? "max-h-[500px]" : "max-h-0"
                        )}
                      >
                        <div
                          className={cn(
                            "transform transition-all duration-500 ease-out",
                            isExpanded ? "translate-y-0 opacity-100" : "translate-y-2 opacity-0"
                          )}
                        >
                          {/* Mostra sempre "I nostri servizi" nel menu mobile */}
                          {item.href && (
                            <Link
                              href={`/${currentLocale}${item.href[currentLocale]}`}
                              onClick={closeDrawer}
                              className={cn(
                                "block hover:text-light-yellow text-light-yellow pt-2.5 text-lg",
                                "transform transition-all flex items-center gap-3",
                                isExpanded ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2",
                                {
                                  "font-medium": isServicesPath(pathname) && !isServiceDetailPage(pathname),
                                }
                              )}
                              style={{
                                transitionDuration: "500ms",
                                transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                                transitionDelay: isExpanded ? "100ms" : "0ms",
                              }}
                            >
                              <span className="icon">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12 5V19M5 12H19"
                                    stroke="#FFF96B"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              {currentLocale === "it" ? "I nostri servizi" : "Our services"}
                            </Link>
                          )}
                          {item.subItems.map((subItem, index) => {
                            const localizedSubHref = subItem.href[currentLocale];

                            return (
                              <Link
                                key={subItem.label}
                                href={localizedSubHref}
                                onClick={closeDrawer}
                                className={cn(
                                  "block hover:text-light-yellow text-light-yellow pt-2.5 text-lg",
                                  "transform transition-all flex items-center gap-3",
                                  isExpanded ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-2"
                                )}
                                style={{
                                  transitionDuration: "500ms",
                                  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                                  transitionDelay: isExpanded ? `${index * 50 + 150}ms` : "0ms",
                                }}
                              >
                                <span
                                  className={cn("icon", {
                                    "[&>svg>g]:opacity-100 [&>svg>g>path]:!stroke-light-yellow [&>svg>g]:!stroke-light-yellow [&>svg>path]:!stroke-light-yellow [&>svg>path]:opacity-100":
                                      true,
                                    "ux-ui-icon [&>svg>path]:!fill-light-yellow": subItem.label === "UX/UI",
                                  })}
                                >
                                  {subItem.icon}
                                </span>
                                {subItem.label}
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

              <div className={cn("w-full mt-[2vh] mb-10")}>
                {isOnServiceDetailPage ? (
                  <Link
                    href="#form-section"
                    onClick={(e) => {
                      e.preventDefault();
                      closeDrawer();
                      // Find the form section in service detail pages
                      const formElement = document.getElementById("form-section");
                      if (formElement) {
                        formElement.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    <Button color="yellow" className="w-64 md:w-72 p-11 md:p-10 text-4xl mt-2 md:text-5xl">
                      <h2>{t("letsTalk")}</h2>
                    </Button>
                  </Link>
                ) : (
                  <Button
                    color="yellow"
                    className="w-64 md:w-72 p-11 md:p-10 text-4xl mt-2 md:text-5xl"
                    onClick={() => {
                      closeDrawer();
                      openContactForm();
                    }}
                  >
                    <h2>{t("letsTalk")}</h2>
                  </Button>
                )}
              </div>
            </div>
            <div className="flex flex-col text-xl mt-10 mb-[7vh]">
              <div className="mr-auto pb-3">
                <LanguageSwitcher />
              </div>
              <div className="pb-6 font-semibold">
                <h3>{t("followUsOn")}</h3>
              </div>
              <div className="leading-8">
                <Link href="https://www.instagram.com/armonia.io/" target="_blank">
                  <h4>Instagram</h4>
                </Link>
                <Link href="https://www.linkedin.com/company/armoniadigital/" target="_blank">
                  <h4 className="mt-1">Linkedin</h4>
                </Link>
                <Link href="https://www.facebook.com/armonia.io" target="_blank">
                  <div className="flex items-center">
                    <h4>Facebook</h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="">
            <div
              className="relative w-10 h-10 flex justify-center items-center cursor-pointer"
              onClick={() => {
                setMenuOpen(!isMenuOpen);
                setExpandedMenu(null);
              }}
            >
              <div
                className={`w-8 h-0.5 bg-white absolute transition-all duration-300 ease-in-out 
                ${isMenuOpen ? "rotate-45" : "-translate-y-1.5"}`}
              ></div>
              <div
                className={`w-8 h-0.5 bg-white absolute transition-all duration-300 ease-in-out 
                ${isMenuOpen ? "-rotate-45" : "translate-y-1.5"}`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
