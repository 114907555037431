"use client";

import { useForm } from "react-hook-form";
import { Button } from "@/components/button";
import { useState, useRef, useEffect } from "react";
import { ArrowBottomIcon } from "./icons";
import { cn } from "@/utils/cn";
import RoundTitle from "./round-title";
import gsap from "gsap";
import { useTranslations, useLocale } from "next-intl";
import { SEND_EMAIL } from "@/graphql/queries";
import client from "@/graphql/client";
import { usePathname } from "next/navigation";
import { X } from "lucide-react";
import Link from "next/link";
import { useContactForm } from "@/store/contact-form";

type FormValues = {
  fullName: string;
  email: string;
  phoneNumber: string;
  company: string;
  service: string;
  budget: string;
  message: string;
  terms: boolean;
};

type SendEmailResponse = {
  sendEmail: {
    sent: boolean;
    to: string;
    replyTo: string;
    origin: string;
    message: string;
    clientMutationId: string;
  };
};

export const ContactForm = () => {
  const t = useTranslations("Form");
  const locale = useLocale();
  const { isOpen, close } = useContactForm();

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isBudgetOpen, setIsBudgetOpen] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [selectedBudget, setSelectedBudget] = useState("");
  const [loading, setLoading] = useState(false);
  const serviceRef = useRef<HTMLDivElement>(null);
  const budgetRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<FormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const handleFormSuccess = () => {
    window.location.href = `/${locale}/thank-you`;
  };

  const handleFormClose = () => {
    close();
    reset();
    setSelectedService("");
    setSelectedBudget("");
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      gsap.fromTo(formRef.current, { opacity: 0, y: 1000 }, { opacity: 1, y: 0, duration: 0.5, ease: "power1.out" });
    }
  }, [isOpen]);

  useEffect(() => {
    const scrollY = window.scrollY;

    if (isOpen) {
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = "100%";
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }

    return () => {
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (serviceRef.current && !serviceRef.current.contains(event.target as Node)) {
        setIsSelectOpen(false);
      }
      if (budgetRef.current && !budgetRef.current.contains(event.target as Node)) {
        setIsBudgetOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let targetScrollTop = 0;

    const handleWheel = (e: WheelEvent) => {
      if (isOpen && modalRef.current) {
        e.preventDefault();

        // Calculate the scroll delta with smoother control
        const scrollMultiplier = 1.0; // Adjust for scroll sensitivity
        targetScrollTop = Math.max(
          0,
          Math.min(
            modalRef.current.scrollTop + e.deltaY * scrollMultiplier,
            modalRef.current.scrollHeight - modalRef.current.clientHeight
          )
        );

        // Use GSAP with shorter duration for more responsive feel
        gsap.to(modalRef.current, {
          scrollTop: targetScrollTop,
          duration: 0.3, // Reduced from 0.5 for faster response
          ease: "power2.out",
          overwrite: true, // Add overwrite to prevent animation queue buildup
        });
      }
    };

    if (isOpen && modalRef.current) {
      window.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      window.removeEventListener("wheel", handleWheel);
      gsap.killTweensOf(modalRef.current);
    };
  }, [isOpen]);

  const services = [
    t("appDevelopment"),
    t("websites"),
    "Branding",
    "UI/UX",
    t("softwareDevelopment"),
    "SEO",
    t("digitalConsulting"),
  ];

  const budgetOptions = ["500 - 2.000", "2.000 - 7.000", "7.000 - 15.000", "15.000 - 30.000", ">30.000"];

  const onSubmit = async (data: FormValues) => {
    setLoading(true);

    // Validate required fields
    if (!data.fullName || !data.email || !selectedService || !selectedBudget) {
      setLoading(false);
      return;
    }

    const body = `
      Nome e Cognome: ${data.fullName}<br />

      Email: ${data.email}<br />

      Numero di Telefono: ${data.phoneNumber}<br />

      Azienda: ${data.company}<br />

      Servizio: ${data.service}<br />

      Budget: ${data.budget} €<br />

      Dettagli della richiesta: ${data.message}<br />
    `;

    try {
      const result = await client.request<SendEmailResponse>(SEND_EMAIL, {
        input: {
          to: "hi@armonia.io",
          subject: data.fullName,
          body,
          from: data.email,
          replyTo: data.email,
          clientMutationId: "contact-form-submission",
        },
      });

      if (result.sendEmail.sent) {
        handleFormSuccess();
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error("Error sending email:", err);
      setLoading(false);
    }
  };

  const toggleSelect = () => {
    setIsSelectOpen((prev) => !prev);
    setIsBudgetOpen(false);
  };

  const toggleBudget = () => {
    setIsBudgetOpen((prev) => !prev);
    setIsSelectOpen(false);
  };

  const handleServiceSelect = (service: string) => {
    setSelectedService(service);
    setValue("service", service);
    setIsSelectOpen(false);
  };

  const handleBudgetSelect = (budget: string) => {
    setSelectedBudget(budget);
    setValue("budget", budget);
    setIsBudgetOpen(false);
  };

  if (!isOpen) return null;

  return (
    <>
      <div ref={modalRef} className="fixed inset-0 z-50 bg-white/60 backdrop-blur-xl overflow-y-auto scrollbar-hide">
        <div className="fixed inset-0 z-50 flex items-center justify-center pointer-events-none">
          <div className="absolute inset-0">
            <div className="flex min-h-full items-start justify-center p-0">
              <div ref={formRef} className="w-full flex flex-col gap-12 pointer-events-auto">
                <div className="flex flex-col gap-10 md:gap-20 bg-dark-gray rounded-2xl m-2.5 px-9 pt-14">
                  <div className=" flex items-center justify-between bg-dark-gray pt-2 pb-4 top-0 z-10">
                    <RoundTitle theme="light" className="!opacity-100">
                      {t("letsTalk")}
                    </RoundTitle>
                    <button className="text-white" onClick={handleFormClose}>
                      <X className="h-10 w-10" />
                    </button>
                  </div>
                  <div className="flex flex-col lg:flex-row items-start justify-between gap-x-16 w-full mb-20 md:mb-36">
                    <div className="top-0 mb-20 md:mb-24 lg:mb-36 w-full lg:w-2/5 flex flex-col gap-8 md:gap-10 lg:gap-12">
                      <div className="text-5xl md:text-8xl font-light text-white">{t("talkAboutNewProject")}</div>
                      <p className="text-grey-mid mt-4 text-2xl">{t("readyToTransform")}</p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="w-full lg:w-3/6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-3.5">
                        <div>
                          <label className="block mb-2 text-white">{t("fullName")} *</label>
                          <input
                            {...register("fullName", { required: t("fullnameRequired") })}
                            placeholder="Mario Rossi"
                            className="w-full px-3.5 py-3 bg-white rounded-xl focus:outline-none"
                          />
                          {isSubmitted && errors.fullName && (
                            <span className="text-red-500 text-sm">{errors.fullName.message}</span>
                          )}
                        </div>

                        <div>
                          <label className="block mb-2 text-white">Email *</label>
                          <input
                            {...register("email", {
                              required: t("emailRequired"),
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t("validEmail"),
                              },
                            })}
                            placeholder="mario.rossi@gmail.com"
                            className="w-full px-3.5 py-3 bg-white rounded-xl focus:outline-none"
                          />
                          {isSubmitted && errors.email && (
                            <span className="text-red-500 text-sm">{errors.email.message}</span>
                          )}
                        </div>

                        <div>
                          <label className="block mb-2 text-white">{t("number")}</label>
                          <input
                            {...register("phoneNumber")}
                            placeholder="+39 333 123 4567"
                            className="w-full px-3.5 py-3 bg-white  rounded-xl focus:outline-none"
                          />
                        </div>

                        <div>
                          <label className="block mb-2 text-white">{t("company")}</label>
                          <input
                            {...register("company")}
                            placeholder="Armonia S.r.l."
                            className="w-full px-3.5 py-3 bg-white rounded-xl focus:outline-none"
                          />
                          {isSubmitted && errors.company && (
                            <span className="text-red-500 text-sm">{errors.company.message}</span>
                          )}
                        </div>

                        <div className="relative" ref={serviceRef}>
                          <label className="block mb-2 text-white">{t("service")} *</label>
                          <button
                            type="button"
                            onClick={toggleSelect}
                            className="w-full px-3.5 py-3 bg-white  rounded-xl focus:outline-none flex justify-between items-center"
                          >
                            <span className={cn({ "text-dark-gray": selectedService })}>
                              {selectedService || t("selectService")}
                            </span>
                            <ArrowBottomIcon className={cn("transition-transform", { "rotate-180": isSelectOpen })} />
                          </button>
                          {isSelectOpen && (
                            <div className="absolute z-50 w-full mt-1 bg-white border border-dark-gray rounded-xl p-1">
                              {services.map((service) => (
                                <div
                                  key={service}
                                  className="p-3 hover:bg-dark-gray rounded-xl hover:text-white cursor-pointer"
                                  onClick={() => handleServiceSelect(service)}
                                >
                                  {service}
                                </div>
                              ))}
                            </div>
                          )}
                          {isSubmitted && !selectedService && (
                            <span className="text-red-500 text-sm">{t("serviceRequired")}</span>
                          )}
                        </div>

                        <div className="relative" ref={budgetRef}>
                          <label className="block mb-2 text-white">Budget *</label>
                          <button
                            type="button"
                            onClick={toggleBudget}
                            className="w-full p-3 bg-white  rounded-xl focus:outline-none flex justify-between items-center"
                          >
                            <span className={cn({ "text-dark-gray": selectedBudget })}>
                              {selectedBudget || t("selectBudget")}
                            </span>
                            <ArrowBottomIcon className={cn("transition-transform", { "rotate-180": isBudgetOpen })} />
                          </button>
                          {isBudgetOpen && (
                            <div className="absolute z-50 w-full mt-1 bg-white border border-dark-gray rounded-xl p-1">
                              {budgetOptions.map((budget) => (
                                <div
                                  key={budget}
                                  className="p-3 hover:bg-dark-gray rounded-xl hover:text-white cursor-pointer"
                                  onClick={() => handleBudgetSelect(budget)}
                                >
                                  {budget}
                                </div>
                              ))}
                            </div>
                          )}
                          {isSubmitted && !selectedBudget && (
                            <span className="text-red-500 text-sm">{t("budgetRequired")}</span>
                          )}
                        </div>
                      </div>
                      <div className="mt-3.5">
                        <label className="block mb-2 text-white">{t("message")}</label>
                        <textarea
                          {...register("message")}
                          placeholder={t("writeYourMessage")}
                          rows={4}
                          className="w-full p-3 bg-white  rounded-xl focus:outline-none"
                        />
                      </div>

                      <div className="flex items-start gap-4 mt-8">
                        <input
                          type="checkbox"
                          id="terms-checkbox"
                          {...register("terms", { required: t("termsRequired") })}
                          className=" w-5 h-5 flex-shrink-0 cursor-pointer appearance-none bg-white checked:bg-white checked:before:content-['✓'] checked:flex checked:items-center checked:justify-center checked:text-dark-gray "
                          style={{ borderRadius: "6px" }}
                        />
                        <label htmlFor="terms-checkbox" className="text-sm text-white cursor-pointer">
                          {t("declare")}{" "}
                          <Link href={`/${locale}/privacy`} className=" text-light-yellow" onClick={close}>
                            {t("privacy")}
                          </Link>{" "}
                          {t("accept")}
                        </label>
                      </div>
                      {isSubmitted && errors.terms && (
                        <span className="text-red-500 text-sm block">{errors.terms.message}</span>
                      )}

                      <div className="mt-8">
                        <Button type="submit" color="yellow" disabled={loading}>
                          {t("sendRequest")}
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mx-2.5 text-dark-gray mb-20">
                  <div className="flex flex-col sm:flex-row gap-10 sm:gap-24 mx-9">
                    <div className="flex flex-col text-lg">
                      <div className="pb-5 font-medium">{t("writeUs")}</div>
                      <div>+39 331 399 8288</div>
                      <div>hi@armonia.io</div>
                    </div>

                    <div className="flex flex-col text-lg">
                      <div className="pb-5 font-medium">{t("visitUs")}</div>
                      <div>Via Paolo Grisignano, 4 </div>
                      <div>Salerno (SA)</div>
                    </div>

                    <div className="flex flex-col text-lg">
                      <div className="pb-5 font-medium">{t("followUs")}</div>
                      <Link href="https://www.instagram.com/armonia.io/" target="_blank">
                        <div>Instagram</div>
                      </Link>
                      <Link href="https://www.linkedin.com/company/armoniadigital/" target="_blank">
                        <div className="mt-1">Linkedin</div>
                      </Link>
                      <Link href="https://www.facebook.com/armonia.io" target="_blank">
                        <div>Facebook</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
