"use client";
import React from 'react';
import { useBlur } from '@/store/blur';

export const Blur: React.FC = () => {
  const { isBlur } = useBlur();

  return (
    <div className={`fixed z-50 top-0 left-0 w-full h-full bg-white bg-opacity-100 backdrop-blur-xl transition-opacity duration-150 ${isBlur ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} />
  );
};
