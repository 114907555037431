import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/animations/scroll-smoother.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/blur.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/contact-form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/favicon-animator.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/legal-banner.tsx")